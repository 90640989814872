@use "@els/els-styleguide-core/scss/core" as *;

button {
  color: $els-color-text;
}

.c-els-header {
  border-bottom: $els-thick-keyline-width solid $els-color-n0;
  display: block;
  height: auto;

  &__container {
    min-height: $els-space * 5;

    @include mq($until: mobile) {
      min-height: $els-space * 3;
    }
  }

  &__logo-img {
    width: $els-space * 3.375;

    @include mq($until: mobile) {
      width: $els-space * 2.25;
    }
  }
}

.u-scm-overflow-hidden {
  min-height: 0;
  overflow: hidden;
}

.u-scm-button-disabled {
  cursor: not-allowed;
  background: $els-color-n3;
  border-color: $els-color-n3;
}

.c-els-nav-group {
  min-height: 0;

  &__item {
    margin: 0;
    min-height: 0;
  }

  &__link {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    &--disabled,
    &--disabled:hover {
      color: $els-color-n4;
      border-color: transparent;
      cursor: not-allowed;
    }
  }

  &--vertical {
    .c-els-nav-group__item {
      padding: $els-space-1o2 0;
      display: block;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.c-els-footer {
  margin: $els-base-space 0 0 0;
  padding: $els-space-2x 0;

  &__logo-link {
    width: max-content;
    max-width: 100%;
  }
}

//Fixes indent on nested fields inside radios or checkbox components
.c-els-field--checkbox,
.c-els-field--radio {
  .c-els-field {
    .c-els-field__label-text {
      padding: 0;
    }
  }
}

.c-els-list.c-els-list {
  &--no-indent {
    & > .c-els-list__item {
      &:before {
        padding-left: 0;
      }
    }
  }
}

.gauge-chart {
  margin: 0;
}

.u-cw-min-width-0 {
  min-width: 0;
}

.u-cw-line-height-1x4o10 {
  height: $els-space * $els-lh-default;
}

.u-cw-cursor-grab {
  cursor: grab;
}

.u-cw-position-relative {
  position: relative;
}

.u-cw-position-absolute {
  position: absolute;
}

.u-scm-visibility-hidden {
  visibility: hidden;
}

.c-scm-button-link.c-els-link {
  color: $els-color-secondary;

  .c-els-link__text {
    color: $els-color-secondary;
  }

  &:hover {
    .c-els-link__text {
      color: $els-color-primary
    }
  }
}

.o-els-table-layout {
  border-collapse: collapse
}

.u-cw-border-bottom {
  border-bottom-width: $els-thin-keyline-width;
  border-bottom-style: solid;
  border-bottom-color: $els-color-n2;
}

.u-cw-flex-grow-2 {
  flex-grow: 2;
}

.u-cw-break-word {
  word-break: normal;
  overflow-wrap: anywhere;
}

.u-cw-nested-checkbox-margin {
  margin-left: $els-space + $els-space-3o4
}

.c-els-link--cw-disabled {
  color: $els-color-n5;
  cursor: not-allowed;

  &:hover {
    color: $els-color-n5;

    .c-els-link__text {
      color: $els-color-n5;
      border: transparent;
    }
  }

  .c-els-link__text {
    color: $els-color-n5;
  }

}

.c-els-list--cw-small {
  .c-els-list__item {
    margin-top: $els-space-1o4 !important;
    margin-bottom: $els-space-1o4 !important;
  }
}

.c-els-field--checkbox.c-els-field--no-label.c-els-field--small .c-els-field__label-text,
.c-els-field--radio.c-els-field--no-label.c-els-field--small .c-els-field__label-text {
  padding: 0;
}

.c-els-modal--no-overflow .c-els-modal__window {
  overflow: visible;
}

ul.c-els-list--cw-success > li.c-els-list__item:before {
  color: $els-color-confirm
}

button:focus-visible,
[type="button"]:focus-visible,
[type="reset"]:focus-visible,
[type="submit"]:focus-visible {
  @include focus-default;
}

.u-cw-max-width-8x {
  max-width: $els-space-1x * 8;
}

.c-els-card {
  &--migrated-course-msg {
    background-color: $els-color-extended-blue-0;
    border-color: $els-color-extended-blue-2;
    margin-bottom: $els-space;
  }
}

.c-els-nav-group__link .c-els-flyout-container__button {
  color: $els-color-n9
}

.c-scm-nav-item__tooltip .c-els-flyout__window {
  padding: $els-space-1o2;
}

.c-scm-training-link-item {
  margin-bottom: $els-space-1o2;

  &:last-child {
    margin-bottom: 0;
  }
}

.o-els-icon-svg--1x1o4 {
  width: 1.25 * $els-space;
  height: 1.25 * $els-space;
}

//.c-els-footer--modified.u-els-margin-left.u-els-margin-right {
//  margin-left: 0 !important;
//  margin-right: 0 !important;
//}

.c-ssa-error-boundary {
  padding: $els-space;

  &__box {
    max-width: 50 * $els-space;
    margin: $els-space-2x auto;
    border: $els-thick-keyline-width solid $els-color-n2;
    border-radius: $els-space;
    text-align: center;
    padding: $els-space-3x;
  }
}

.u-els-background-color-info-background {
  background-color: #EBF8FF;
}

.u-els-background-color-confirm-background {
  background-color: #ECFDF1
}
