@use "@els/els-styleguide-core/scss/core" as *;

$ssa-chat-page-width: 50 * $els-space;
$ssa-chat-font-size: 1.15 * $els-space;

.c-els-ai-chat {

  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 1px;

  &__width {
    max-width: $ssa-chat-page-width;
    margin: 0 auto;
    min-height: 1px;
    height: 100%;
  }

  &__top {
    padding: $els-space-1x $els-space-2x;

    @include mq($until: mobile) {
      padding: $els-space-1x $els-space-1x;
    }
  }

  &__center {
    flex: 1;
    padding: $els-space-1o4 $els-space-2x;
    min-height: 1px;

    @include mq($until: mobile) {
      padding: $els-space-1o4 $els-space-1x;
    }
  }

  &__explainer {
    border-radius: $els-space;
    border: $els-thick-keyline-width solid $els-color-n3;
    padding: $els-space-2x;

    .c-els-list__item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &__explainer-examples {
    padding-left: 1.75 * $els-space;

  }

  &__entries {
    margin: 0 auto;
    overflow-y: auto;
    height: 100%;
    box-shadow: inset 0 -.5rem .5rem -.5rem $els-color-n0;
  }

  &__entry {
    margin-right: 6.5rem;

    @include mq($until: mobile) {
      margin-right: 0;
    }
  }

  &__bottom {
    bottom: 0;
    padding: $els-space $els-space-2x $els-space-1o2 $els-space-2x;

    @include mq($until: mobile) {
      padding: $els-space $els-space $els-space-1o2 $els-space;
    }
  }

  &__prompt {
    margin-left: $els-space * 3.8;

    @include mq($until: mobile) {
      margin-left: 0;
    }

    textarea {
      resize: none;
      font-size: $ssa-chat-font-size;
    }
  }

  &__entry {

    position: relative;

    &-main {
      overflow: hidden;

      &--active {
        border-color: $els-color-primary !important;
      }

    }

    &-text {

      pre {
        text-wrap: wrap;
        white-space: pre-wrap;
        font-family: $els-font-base;
      }
    }

    &--bot {
      text-align: left;
      margin-left: $els-space * 2.25;
      margin-bottom: $els-space-2x;

      .c-els-ai-chat__entry-author {
        position: relative;
        padding-left: $els-space-1x1o2;
        color: $els-color-n7;
        font-size: $els-fs-meta;
        margin-bottom: $els-space-1o2;
        line-height: $els-lh-default;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          background: $els-color-extended-orange-1;
          height: $els-space;
          width: $els-space;
          margin: -$els-space_1o2 0 0 ((-1 * $els-space_1o2) + ($els-space / 16));
        }
      }

      .c-els-ai-chat__entry-author-heading {
        line-height: $els-lh-default;
      }

      .c-els-ai-chat__entry-main {
        border-left: $els-thick-keyline-width solid $els-color-extended-orange-1;
      }

      .c-els-ai-chat__entry-main {
        padding: 0 0 0 $els-space-1x1o2;
        font-size: $ssa-chat-font-size;
      }
    }

    &--user {
      margin-bottom: $els-space-3o4;


      .c-els-ai-chat__entry-author {
        position: relative;
        padding-left: 1.75 * $els-space;
        color: $els-color-n7;
        font-size: $els-fs-meta;
        margin-bottom: $els-space-1o2;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          background: $els-color-extended-green-2;
          border-radius: $els-space;
          height: $els-space;
          width: $els-space;
          margin: -$els-space_1o2 0 0 0;
        }
      }

      .c-els-ai-chat__entry-main {
        padding-left: 1.75 * $els-space;
      }

      .c-els-ai-chat__entry-text {
        font-size: $els-fs-body-large;
      }
    }
  }

  &__entry-reference-list {
    margin: 0;
  }

  &__entry-reference {
    margin-bottom: $els-space-1o2;
    font-size: $els-fs-meta;
    word-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__entry-control {
    display: block;

    &--active {
      svg {
        fill: red
      }
    }

    &--liked {
      svg {
        fill: $els-color-confirm
      }
    }

    &--not-liked {
      svg {
        fill: $els-color-warn
      }
    }

  }

  &__entry-controls {
    margin: $els-space-1x 0 0 0;
  }

  &__entry-feedback {
    padding: $els-space;
    background-color: $els-color-n0;
    margin-top: $els-space;

  }

  &__feedback-face {
    display: block;
    border-radius: $els-space;
    color: $els-color-secondary;

    &-icon {
      display: block;
    }

    &--active {
      color: $els-color-n10;
    }
  }

  &__rec-list {
    margin: $els-space 0;
  }

  &__osmosis-iframe {
    aspect-ratio: 16 / 9;
    max-width: 100%;
    width: $els-space * 28;
    display: block;
    border: 0;
  }

  &__submit-btn {
    height: 100%;
    width: 5.5 * $els-space;
    color: $els-color-secondary;
  }

  &__citation-table {
    td {
      vertical-align: top;
      padding-bottom: $els-space-1o2;
    }
  }

  &__citation-table-col-1 {
    min-width: $els-space-3x + $els-space-1o2;
    padding-right: $els-space-1o4;
    @include mq($until: mobile) {
      min-width: 0;
      padding-right: $els-space-1o2;
    }
  }

  &__citation-table-col-2 {
    min-width: $els-space-3x;
    padding-right: $els-space-1o4;
    @include mq($until: mobile) {
      min-width: 0;
    }
  }

  &__user-materials {
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
  }

  &__user-materials-title-parent {
    overflow: auto;
  }

  &__user-materials-title {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: $els-space-1x1o2;
  }

  &__student-themes {
    border-radius: $els-space-1o2;
    padding: $els-space-2x;
    background-color: $els-color-n0;
  }

  &__student-themes-headings {
    font-size: $els-fs-h4;
    margin-top: $els-space-1o2;
  }


}

