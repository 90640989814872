@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/remediation-color-settings" as *;

.c-ssa-remediation-goal-modal {
  max-width: 50 * $els-space;

  &__progress {
    background-color: white;
    padding: $els-space;
    border-radius: $els-space-1o2;
    border: $els-thin-keyline-width solid $els-color-n1;
    white-space: nowrap;

    .c-els-progress-bar {
      background: $els-color-n1;
    }

    .c-els-progress-bar__progress {
      background: $els-color-n9;
    }
  }

  &__card-wrap {
    max-width: 40 * $els-space;
    margin: $els-space-1x auto;
  }

  &__skip-form {
    border-radius: $els-space-1o2;
    border: $els-thin-keyline-width solid $els-color-n1;
    padding: $els-space-1x $els-space-2x
  }

  &__card {
    border-radius: $els-space-1o2;
    border: $els-thin-keyline-width solid $els-color-n3;
    padding: $els-space-1x;

    &--quiz {
      text-align: center;
    }
  }

  &__list-item {

    margin: $els-space 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__goal-table {
    text-align: left;
    td {
      padding: $els-space-1o2 0;
      vertical-align: top;
      &:first-child {
        padding-right: $els-space;
      }
    }
  }

}
