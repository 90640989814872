@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-student-study-page{
  &__nav-buttons {
    margin: $els-space 0 $els-space $els-space;
  }

  &__nav-button-menu {
    border: none;
    margin: $els-space;
  }
}
